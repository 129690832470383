import jwtDecode from 'jwt-decode';
import SuperFetch from './superFetch';
import history from '@iso/lib/helpers/history';

class AuthHelper {
  login = async userInfo => {
    if (!userInfo.email || !userInfo.password) {
      return { error: 'please fill in the input' };
    }
    return await SuperFetch.post('/auth', userInfo);
   /* return await SuperFetch.post('/auth/login', userInfo).then(response => {
      return this.checkExpirity(response.token);
    });*/

  };
  /*async checkDemoPage(token) {
    if (this.checkExpirity(token).error) {
      return { error: 'Token expired' };
    }
    return await SuperFetch.get('secret/test', { token })
      .then(response => ({
        status: '200',
        message: 'Success',
      }))
      .catch(error => ({ error: JSON.stringify(error) }));
  }*/
  checkExpirity = token => {
    //console.log("check", token)
    if (!token) {
      return {
        error: 'not matched',
      };
    }
    try {
      const profile = jwtDecode(token);
      //console.log("profile", profile)
      const expiredAt = profile.expiredAt || profile.exp * 1000;
      if (expiredAt > new Date().getTime()) {
        return {
          ...profile,
          token,
          expiredAt: new Date(expiredAt),
        };
      } else {
        return { error: 'Token expired' };
      }
    } catch (e) {
      //console.log(e);
      return { error: 'Server Error' };
    }
  };
  setSession = (authResult)=> {
    const {token} = authResult;
    if(token){
      //localStorage.setItem('id_token', token);
      //history.replace('/dashboard');
    }
    return token;
  };
  logout = () => {
    localStorage.removeItem('id_token');
    history.replace('/');
  };
}
export default new AuthHelper();
