import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import { Helmet, HelmetProvider } from 'react-helmet-async';


import {DashboardContainer} from "./containers/Dashboard/Dashboard.styles";


const App = () => (

    <Provider store={store}>
    <AppProvider>
      <>
       {/* <HelmetProvider>
            <Helmet>
                <script src={"https://static.landbot.io/landbot-widget/landbot-widget-1.0.0.js"}/>
                <script>
                    var myLandbot = new LandbotLivechat({`{index:'https://chats.landbot.io/v2/H-651477-SYROU9SHRB275BEO/index.html'}`});
                </script>
            </Helmet>
        </HelmetProvider>*/}
        <GlobalStyles />
        <Routes />
      </>
    </AppProvider>
  </Provider>

);
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
