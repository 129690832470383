import AuthHelper from '../../library/helpers/authHelper'
import {message} from "antd";
const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (token = false, loginData) => {
    return async (dispatch) => {
      try {
        //hago el post aqui
        //console.log("llego al loginData", loginData)
        const response =  await AuthHelper.login(loginData)
        token = response.token;
        dispatch(loginApi(token));
      } catch (e) {
        // si hay error
        //console.log("existe error en catch")
        message.error('Error. datos de acceso incorrectos.')

      }
    }
  },
  logout: () => ({
    type: actions.LOGOUT,
  }),
};

const loginApi=(token)=>({
  type: actions.LOGIN_REQUEST,
  payload: { token },
});

export default actions;
